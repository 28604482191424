<script context="module">
</script>

<script lang="ts">
  import Header from "$components/ui/Header.svelte";
  import Title from "$components/ui/Title.svelte";
  import Article from "$components/ui/Article.svelte";
  import PermitEcBarcode from "./PermitECBarcode.svelte";
  import PropertyDataItem from "$components/property/PropertyDataItem.svelte";
  import PermitValidStatus from "./PermitValidStatus.svelte";
  import PermitValid from "./PermitValid.svelte";
  import { datetime } from "$utils/temporal";
  import PermitPass from "./PermitPass.svelte";
  import PermitFees from "./PermitFees.svelte";
  import PermitEntry from "./PermitEntry.svelte";
  import PermitSubjects from "./PermitSubjects.svelte";
  import PermitAncillary from "./PermitAncillary.svelte";
  import PermitNav from "./PermitNav.svelte";

  export let permit: Permit;
  export let valid: boolean = false;
  let policy = permit?.policy;
  let property = permit?.property;
  //export let property;

  //$: console.log("permit=", permit);
  $: policy = permit?.policy;
  $: property = permit?.property;
</script>

<Article class="permit">
  <Header>
    <!-- <PropertyDataItem {property} /> -->
    <Title>{permit?.title || ""}</Title>
  </Header>
  <PermitValid {permit} bind:valid />
  <PermitPass {permit} />
  <PermitSubjects {permit} />

  <PermitEcBarcode {permit} />
  <PermitNav {permit} />
  <PermitEntry {permit} />
  <PermitFees {permit} />
  <PermitAncillary {permit} />

  <slot />
</Article>
