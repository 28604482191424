<script lang="ts" context="module">
  import { shareable } from "$utils/uistores";
  let enabled = false;
  shareable.subscribe((value) => {
    enabled = value;
  });
</script>

<script lang="ts">
  export let url: URL | null | undefined = null;
  export let value: string = "";
  export let title: string | null | undefined = null;
  export let copied: boolean = false;
  export let label: string = "Share";

  function share(e: Event) {
    if (!enabled) return;
    e.preventDefault();
    title = (e.currentTarget as HTMLElement).title;
    if ((e.currentTarget as HTMLAnchorElement).href) {
      navigator.share({
        title,
        url: (e.currentTarget as HTMLAnchorElement).href,
      });
      return;
    }
    const text = (e.currentTarget as HTMLButtonElement).value ?? value;
    // if (url) {
    // navigator.share({
    //   title,
    //   url: url.href,
    // });
    // } else if (URL.canParse(text)) {
    //   navigator.share({
    //     url: text,
    //   });
    // } else {
    navigator.share({
      title,
      text,
    });
    // }
  }
  $: if (!url && URL.canParse(value)) {
    url = new URL(value);
  }
</script>

{#if $shareable}
  {#if url}
    <a href={value} {title} target="_blank" on:click={share}>{label}</a>
  {:else}
    <button
      type="button"
      class="share"
      class:success={copied}
      class:copied
      {value}
      {title}
      on:click={share}>{label}</button
    >
  {/if}
{/if}
