<script lang="ts">
  import ShareButton from "$components/share/ShareButton.svelte";
  import { unended } from ".";

  export let permit: Permit;
</script>

{#if unended(permit)}
  <nav class="actions">
    <ul>
      <li>
        <a
          rel="alternate"
          type="text/calendar"
          href="https://calendar.smartpermit.app/events/{permit.id}"
          >Add to Calendar</a
        >
      </li>
      <li>
        <ShareButton url={new URL(document.URL)} title={permit.title} />
      </li>
    </ul>
  </nav>
{/if}
